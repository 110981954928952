import React from "react"
import { graphql, PageProps } from "gatsby"
import { CasesPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import ArticlesPageLayout from "../layouts/articles-page-layout"

const Cases: React.FC<PageProps<CasesPageProps>> = ({ data }) => {
  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <ArticlesPageLayout
        pageTitle={data?.PAGE_METADATA?.title}
        articles={data?.CASES?.nodes}
        loadMore={data?.PAGE_CONTENT?.casesOptions?.loadMore}
        parent="cases"
      />
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      casesOptions {
        loadMore
      }
    }
    CASES: allWpCase(filter: { locale: { locale: { eq: $locale } } }) {
      nodes {
        id
        uri
        title
        locale {
          locale
        }
        featuredImage {
          node {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
        singleCasesOptions {
          tags
        }
      }
    }
  }
`

export default Cases
