import React, { useEffect, useState } from "react"
import { GridPageLayoutProps } from "../types/pages"
import GridContainer from "../components/shared/grid-container"
import GridItem from "../components/shared/grid-item"
import { FetchedGridItemProps } from "../types/shared-singulars"
import { nestedArray } from "../utils/helpers"

const ArticlesPageLayout: React.FC<GridPageLayoutProps> = ({
  pageTitle,
  articles,
  parent,
  loadMore,
}) => {
  const [shownArticles, setShownArticles] = useState<FetchedGridItemProps[][]>(
    []
  )
  const [numOfArticles, setNumOfArticles] = useState<number>(4)

  function populateGrid() {
    let nextArticles = articles.slice(numOfArticles, numOfArticles + 2)
    setShownArticles([...shownArticles, nextArticles])
    setNumOfArticles(prevState => prevState + 2)
  }

  useEffect(() => {
    setShownArticles([articles?.slice(0, numOfArticles)])
  }, [articles])

  return (
    <GridContainer parent={parent}>
      <div className="grid-container__header">
        <h1 data-aos="fade-up" data-aos-delay="100" data-aos-easing="ease-out">
          {pageTitle}
        </h1>
      </div>

      <div className="grid-container__items">
        {shownArticles &&
          shownArticles.map((array: FetchedGridItemProps[]) =>
            nestedArray(array).map(
              (group: FetchedGridItemProps[], groupIndex: number) =>
                group.map((item: FetchedGridItemProps, index: number) => (
                  <GridItem
                    key={index}
                    index={groupIndex + 1}
                    featuredImage={item?.featuredImage}
                    uri={item.uri}
                    tags={item.singleCasesOptions?.tags?.split(",")}
                  >
                    {item?.singleArticleOptions?.authorSelector && (
                      <div className="grid-item__authors">
                        {item?.singleArticleOptions?.authorSelector?.map(
                          (item: any, index: number) => (
                            <span
                              key={index}
                              className="grid-item__authors__tag"
                            >
                              {item?.title}{" "}
                            </span>
                          )
                        )}
                      </div>
                    )}
                    <div className="grid-item__title-nodes">
                      <h3>{item.title}</h3>
                    </div>
                  </GridItem>
                ))
            )
          )}
      </div>
      <>
        {numOfArticles < articles.length && (
          <div className="grid-container__list-toggler">
            <button
              onClick={populateGrid}
              className="grid-container__list-toggler__button button--outlined"
            >
              {loadMore}
            </button>
          </div>
        )}
      </>
    </GridContainer>
  )
}

export default ArticlesPageLayout
